import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";
import "./app.css";
import { CSpinner } from "@coreui/react";

const loading = (
  <div className=" d-flex justify-content-center item-align-center ">
    <CSpinner
      size="sm"
      variant="grow"
      color="info"
      style={{ width: "2rem", height: "2rem" }}
    />
    <CSpinner
      size="sm"
      variant="grow"
      color="info"
      style={{ width: "2rem", height: "2rem" }}
    />
    <CSpinner
      size="sm"
      variant="grow"
      color="info"
      style={{ width: "2rem", height: "2rem" }}
    />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const UserLogin = React.lazy(() => import("./views/pages/user/login/Login"));
const AdminLogin = React.lazy(() => import("./views/pages/login/AdminLogin"));
const UserDashboard = React.lazy(() =>
  import("./views/pages/user/dashboard/Dashboard")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route
            exact
            path="/login"
            name="Login Page"
            element={<UserLogin />}
          /> */}
          <Route exact path="/" name="Login Page" element={<UserLogin />} />
          <Route
            exact
            path="/user/dashboard"
            name="User Dashboard"
            element={<UserDashboard />}
          />
          <Route
            exact
            path="/admin/login"
            name="Admin Login Page"
            element={<AdminLogin />}
          />
          <Route
            exact
            path="/"
            name="Admin Dashboard"
            element={<DefaultLayout />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";

const initialState = {
  admin: {
    success: false,
    error: "",
    admin: {},
    loading: false,
    isAuthenticated: false,
  },
  allAdmins: {
    error: "",
    allAdmins: [],
    allAdminsForSelect: [],
    loading: false,
    page: 1,
    pageSize: 1,
    pages: 1,
  },
};

export const fetchAllAdmins = createAsyncThunk(
  "all_admins",
  async ({ page = 1, pageSize = 5, search = "" }) => {
    try {
      const url = `/admin/get-all-admins?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.post(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAdmins = createAsyncThunk("fetch_admins", async () => {
  try {
    const { data } = await API.get("/admin/get-all-admins");
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const createAdmin = createAsyncThunk("create_admin", async (payload) => {
  try {
    const { data } = await API.post("/admin/create", payload);
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const loginAdmin = createAsyncThunk("admin_login", async (payload) => {
  try {
    const { data } = await API.post("/admin/auth/login", payload);
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const updateAdminRole = createAsyncThunk(
  "update_role",
  async ({ adminId, role }) => {
    try {
      const { data } = await API.patch(`/admin/update-role/${adminId}`, {
        role,
      });
      return data;
    } catch (error) {
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateAdminStatus = createAsyncThunk(
  "delete_admin",
  async (id) => {
    try {
      const { data } = await API.patch(`/admin/update-delete/${id}`);
      return data;
    } catch (error) {
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const changePassword = createAsyncThunk(
  "update_password",
  async ({ adminId, ...payload }) => {
    try {
      const { data } = await API.patch(
        `/admin/update-password/${adminId}`,
        payload
      );
      return data;
    } catch (error) {
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const permanentDeleteAdmin = createAsyncThunk(
  "permanent_delete",
  async (adminId) => {
    try {
      const { data } = await API.delete(`/admin/permanent-delete/${adminId}`);
      return data;
    } catch (error) {
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const getAdmin = createAsyncThunk("get_admin", () => {
  let admin = localStorage.getItem("admin");
  if (admin) {
    admin = JSON.parse(admin);
    return admin;
  } else {
    return null;
  }
});
export const clearErrSuccess = createAsyncThunk("clear_error", () => {
  return true;
});
export const logout = createAsyncThunk("logout", () => {
  return true;
});
const allAdminSlice = createSlice({
  name: "allAdmins",
  initialState: initialState.allAdmins,
  extraReducers: (builder) => {
    builder.addCase(fetchAllAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAdmins.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdmins = action.payload.allAdmins;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    builder.addCase(fetchAllAdmins.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // fetch admins for listing
    builder.addCase(fetchAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdminsForSelect = action.payload.admins;
      }
    });

    // create new admin
    builder.addCase(createAdmin.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(createAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update role
    builder.addCase(updateAdminRole.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminRole.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdmins = state.allAdmins.map((item) => {
          if (item._id == action.payload.admin._id) {
            item.role = action.payload.admin.role;
            return item;
          }
          return item;
        });
      }
    });
    builder.addCase(updateAdminRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // block unblock admin
    builder.addCase(updateAdminStatus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdmins = state.allAdmins.map((item) => {
          if (item._id == action.payload.admin._id) {
            item.isActive = action.payload.admin.isActive;
            return item;
          }
          return item;
        });
      }
    });
    builder.addCase(updateAdminStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // permanent delete admin
    builder.addCase(permanentDeleteAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(permanentDeleteAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdmins = state.allAdmins.filter(
          (item) => item._id != action.payload.adminId
        );
      }
    });
    builder.addCase(permanentDeleteAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(clearErrSuccess.fulfilled, (state) => {
      state.success = false;
      state.error = "";
    });
  },
});
const adminSlice = createSlice({
  name: "admin",
  initialState: initialState.admin,
  extraReducers: (builder) => {
    builder.addCase(loginAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.isAuthenticated = true;
        state.success = true;
        localStorage.setItem("token", JSON.stringify(action.payload.token));
        state.admin = {
          name: action.payload.admin.name,
          role: action.payload.admin.role[0],
          _id: action.payload.admin._id,
          image: action.payload.admin?.image,
        };

        localStorage.setItem("admin", JSON.stringify(state.admin));
      }
    });
    builder.addCase(loginAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loading = false;
      (state.admin = {}), (state.isAuthenticated = false);
      state.success = false;
    });
    // get admin details
    builder.addCase(getAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload);
    });
    builder.addCase(getAdmin.rejected, (state, action) => {
      state.loading = false;
      state.admin = null;
    });
  },
});

export const adminReducer = adminSlice.reducer;
export const allAdminReducer = allAdminSlice.reducer;

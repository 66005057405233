import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";

const defaultPath = "/click";

const initialState = {
  click: {
    success: false,
    error: "",
    click: {},
    loading: false,
  },
  allClicks: {
    error: "",
    allClicks: [],
    userClicks: [],
    loading: false,
    page: 1,
    pageSize: 1,
    pages: 1,
    success: false,
    summery: [],
  },
};

export const fetchAllClicks = createAsyncThunk(
  "all_Clicks",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    fromDate = "",
    toDate = "",
    user = "",
    link = "",
  }) => {
    try {
      const url = `${defaultPath}/get-all-clicks?page=${page}&pageSize=${pageSize}&search=${search}&fromDate=${fromDate}&toDate=${toDate}&user=${user}&link=${link}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const createClick = createAsyncThunk("create_click", async (payload) => {
  try {
    const { data } = await API.post(`${defaultPath}/create`, payload);
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});
export const deleteClick = createAsyncThunk("delete_click", async (linkId) => {
  try {
    const { data } = await API.patch(`${defaultPath}/delete/${linkId}`);
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const getUserClicks = createAsyncThunk("user_clicks", async () => {
  try {
    const { data } = await API.get(`${defaultPath}/get-user-clicks`);
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const refreshAllClicks = createAsyncThunk("refresh_clicks", () => {
  return true;
});

const allClickSlice = createSlice({
  name: "allClicks",
  initialState: initialState.allClicks,
  extraReducers: (builder) => {
    builder.addCase(fetchAllClicks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllClicks.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allClicks = action.payload.allClicks;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
        state.summery = action.payload.summery;
      }
    });
    builder.addCase(fetchAllClicks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create link
    builder.addCase(createClick.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createClick.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.userClicks.push(action.payload.click.linkId);
      }
    });
    builder.addCase(createClick.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // get user clicks
    builder.addCase(getUserClicks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserClicks.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.userClicks = action.payload.userClicks;
      }
    });
    builder.addCase(getUserClicks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(refreshAllClicks.fulfilled, (state) => {
      state.error = "";
      state.success = false;
    });
  },
});

export const allClickReducer = allClickSlice.reducer;

import React from 'react'
import '../alert.css'
function Success({ message }) {
    return (
        <div className="border border-success bg-success text-white  rounded p-2 pe-4 alert d-flex ">
            <img className='me-3 my-auto' src="http://www.moneycollect.com/static/common/home/img/success.gif" alt="" width={40} height={40} />
            <p className='my-auto'>{message} </p>
        </div>
    )
}

export default Success
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Success from "src/components/alerts/Success/Success";
import Danger from "src/components/alerts/Danger/Danger";

const defaultPath = "/user";

const initialState = {
  allUser: {
    loading: false,
    allUsers: [],
    allUsersForAdmin: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
    success: false,
  },
  userDetails: {
    loading: false,
    user: {},
    editUser: {},
    error: "",
    isAuthenticated: false,
  },
};

export const fetchAllUsers = createAsyncThunk(
  "all_users",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/get-all-users?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.post(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      console.log(error);
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllUsersForAdmin = createAsyncThunk(
  "all_users_for_admin",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/get-all-users`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "user_details",
  async (userId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${userId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const loginUser = createAsyncThunk("login_user", async (payload) => {
  try {
    const { data } = await API.post(`${defaultPath}/login`, payload);
    return data;
  } catch (error) {
    if (error.response.status == 404) {
      return toast.custom(
        <Danger
          message={
            "You are not registered. Contact admin at connect@indiancashabck.com"
          }
        />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const createNewUser = createAsyncThunk(
  "create_new_user",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const resetAllUsers = createAsyncThunk("reset_user", () => {
  return true;
});

export const clearUserError = createAsyncThunk("clear_user", () => {
  return true;
});
export const updateUserStatus = createAsyncThunk(
  "update_user_status",
  async (userId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-status/${userId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const editUser = createAsyncThunk("edit_user", async (payload) => {
  try {
    const { data } = await API.put(
      `${defaultPath}/update/${payload.userId}`,
      payload
    );
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});
export const getUserDetails = createAsyncThunk(
  "user_details",
  async (userId) => {
    try {
      const { data } = await API.post(
        `${defaultPath}/get-user-details/${userId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const allUserSlice = createSlice({
  name: "AllUsers",
  initialState: initialState.allUser,
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsers = action.payload.allUsers;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });

    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // users for admin
    builder.addCase(fetchAllUsersForAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsersForAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsersForAdmin = action.payload.allUsers;
      }
    });
    builder.addCase(fetchAllUsersForAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create new user
    builder.addCase(createNewUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createNewUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.success = true;
      }
    });
    builder.addCase(createNewUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetAllUsers.fulfilled, (state) => {
      state.loading = false;
      state.success = false;
      state.error = "";
    });
    // change user status
    builder.addCase(updateUserStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsers.map((item) => {
          if (item._id === action.payload.user._id) {
            item.isActive = action.payload.user.isActive;
            return item;
          }
          return item;
        });
      }
    });
    builder.addCase(updateUserStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const userDetailSlice = createSlice({
  name: "userDetails",
  initialState: initialState.userDetails,
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("token", JSON.stringify(action.payload.token));
      }
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(clearUserError.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.error = "";
      state.success = false;
    });
    // get user details
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.user = action.payload.user;
      }
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // edit user
    builder.addCase(editUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.loading = false;
      toast.custom(<Success message={"Successfully updated user details"} />);
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allUserReducer = allUserSlice.reducer;
export const userDetailsReducer = userDetailSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";

const defaultPath = "/admin";

const initialState = {
  error: "",
  allActivities: [],
  loading: false,
  page: 1,
  pageSize: 1,
  pages: 1,
};

export const fetchAllActivities = createAsyncThunk(
  "fetch_activities",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    admin = "",
    activityType = "",
  }) => {
    try {
      const link = `${defaultPath}/get-all-activities?page=${page}&pageSize=${pageSize}&search=${search}&admin=${admin}&activityType=${activityType}`;
      const { data } = await API.get(link);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const activitySlice = createSlice({
  name: "activities",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllActivities.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allActivities = action.payload.allActivities;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    builder.addCase(fetchAllActivities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const activityReducer = activitySlice.reducer;

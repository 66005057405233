import React from 'react'
import '../alert.css'
function Danger({ message }) {
    return (
        <div className="border border-danger bg-danger text-white  rounded p-2 pe-4 alert d-flex ">
            <img className='me-3 my-auto' src="https://cliply.co/wp-content/uploads/2021/07/372107370_CROSS_MARK_400px.gif" alt="" width={40} height={40} />
            <p className='my-auto'>{message} </p>
        </div>
    )
}

export default Danger;
import { configureStore } from "@reduxjs/toolkit";
import { adminReducer, allAdminReducer } from "./features/adminSlice";
import sidebarReducer from "./features/sidebarSlice";
import { allUserReducer, userDetailsReducer } from "./features/userSlice";
import { allClickReducer } from "./features/clickSlice";
import { allLinkReducer, linkDetailsReducer } from "./features/linkSlice";
import { activityReducer } from "./features/activitySlice";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    allAdmins: allAdminReducer,
    allUsers: allUserReducer,
    userDetails: userDetailsReducer,
    sidebar: sidebarReducer,
    allClicks: allClickReducer,
    allLinks: allLinkReducer,
    linkDetails: linkDetailsReducer,
    activities: activityReducer,
  },
});

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import Success from "src/components/alerts/Success/Success";

const defaultPath = "/link";

const initialState = {
  link: {
    success: false,
    error: "",
    link: {},
    loading: false,
  },
  allLinks: {
    error: "",
    allLinks: [],
    allLinkForAdmin: [],
    loading: false,
    page: 1,
    pageSize: 1,
    pages: 1,
    success: false,
  },
};

export const fetchAllLinks = createAsyncThunk(
  "all_links",
  async ({ page = 1, pageSize = 5, search = "", admin = "" }) => {
    try {
      const link = `${defaultPath}/get-all-links?page=${page}&pageSize=${pageSize}&search=${search}&admin=${admin}`;
      const { data } = await API.post(link);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllLinksForAdmin = createAsyncThunk(
  "all_links_for_admin",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/get-all-links`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createLink = createAsyncThunk("create_link", async (payload) => {
  try {
    const { data } = await API.post(`${defaultPath}/create`, payload);
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const changeStatus = createAsyncThunk(
  "change_Link_status",
  async (linkId) => {
    try {
      const { data } = await API.patch(`${defaultPath}/delete/${linkId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const getLinkDetails = createAsyncThunk(
  "link_details",
  async (linkId) => {
    try {
      const { data } = await API.post(
        `${defaultPath}/get-link-details/${linkId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const permanentDelete = createAsyncThunk(
  "permanent_delete_link",
  async (linkId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/permanent-delete/${linkId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        return toast.custom(
          <Danger message={error?.response?.data?.errors || error.message} />
        );
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateLinkStatus = createAsyncThunk("update_clicks", (linkId) => {
  return linkId;
});

export const updateLink = createAsyncThunk("update_link", async (payload) => {
  try {
    const { data } = await API.put(
      `${defaultPath}/update/${payload.linkId}`,
      payload
    );
    return data;
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      return toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const clearErrSuccess = createAsyncThunk("clear_error", () => {
  return true;
});
export const refreshAll = createAsyncThunk("refresh", () => {
  return true;
});
const allLinkSlice = createSlice({
  name: "allLinks",
  initialState: initialState.allLinks,
  extraReducers: (builder) => {
    builder.addCase(fetchAllLinks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllLinks.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allLinks = action.payload.allLinks;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    builder.addCase(fetchAllLinks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // all links for admin
    builder.addCase(fetchAllLinksForAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllLinksForAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allLinkForAdmin = action.payload.allLinks;
      }
    });
    builder.addCase(fetchAllLinksForAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create link
    builder.addCase(createLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLink.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.success = true;
        toast.custom(<Success message={"New link created successfully."} />);
      }
    });
    builder.addCase(createLink.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // change active status
    builder.addCase(changeStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.allLinks.map((item) => {
        if (item._id == action.payload.link._id) {
          item.isActive = action.payload.link.isActive;
        }
        return item;
      });
    });
    builder.addCase(changeStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // permanent delete
    builder.addCase(permanentDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(permanentDelete.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"Link deleted permanently."} />);
      }
    });
    builder.addCase(permanentDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update link status
    builder.addCase(updateLinkStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLinkStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.allLinks = state.allLinks.map((item) => {
        if (item._id == action.payload) {
          return { ...item, clicked: true };
        }
        return item;
      });
    });
    builder.addCase(updateLinkStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(refreshAll.fulfilled, (state, action) => {
      state.error = "";
      state.success = false;
      state.loading = false;
    });
  },
});

const linkDetailsSlice = createSlice({
  name: "linkDetails",
  initialState: initialState.link,
  extraReducers: (builder) => {
    // get link details
    builder.addCase(getLinkDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLinkDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.link = action.payload.link;
      }
    });
    builder.addCase(getLinkDetails.rejected, (state, action) => {
      state.loading = false;
    });
    // edit link
    builder.addCase(updateLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLink.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"successfully updated link details"} />);
      }
    });
    builder.addCase(updateLink.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // clear all errors
    builder.addCase(clearErrSuccess.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
      state.success = false;
    });
  },
});

export const allLinkReducer = allLinkSlice.reducer;
export const linkDetailsReducer = linkDetailsSlice.reducer;
